<template>
  <div class="ticket">
    <button
      class="track-ass-btn"
      title="Close Ticket"
      @click.stop="checkClose()"
      :disabled="loading"
    >
      Close
    </button>
    <v-dialog v-model="dialog" max-width="500">
      <v-form ref="form" onSubmit="return false;">
        <v-spacer>
          <v-card>
            <v-card-title class="headline"
              >Please Enter Reason for Closure</v-card-title
            >

            <v-card-text>
              <v-flex xs12 sm12 md12>
                <v-text-field
                  v-model="reason"
                  label="Reason"
                  placeholder="e.g. Customer has already booked."
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </v-flex>
            </v-card-text>
            <v-card-text>
              <v-flex v-if="reason">Reason Preview:</v-flex>
              <v-flex xs12 sm12 md12 class="break_long_text">{{
                reason
              }}</v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="grey darken-1"
                style="color: white"
                @click.prevent="dialog = false"
                :disabled="disStatus"
                >Cancel</v-btn
              >
              <v-btn
                color="orange darken-1"
                style="color: white"
                @click.prevent="closeTicket(ticketId)"
                :loading="loading"
                >Close Ticket</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-spacer>
      </v-form>
    </v-dialog>
    <ErrorBox :error="x.error" />
  </div>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { requestCallbackSectionApiUrl } from "@/constants/api-urls.js";
export default {
  props: {
    ticketId: String,
    callbackCloseTicket: Function,
  },
  components: {
    ErrorBox,
  },
  data() {
    return {
      reason: "",
      dialog: false,
      operationName: "",
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
      },
      x: {
        error: null,
      },
    };
  },
  created() {},
  methods: {
    async checkClose(id) {
      this.loading = true;
      this.operationName = "cancel-request-callback";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.dialog = true;
        this.loading = false;
      } else {
        this.loading = false;
        return;
      }
    },
    async closeTicket(ticketId) {
      this.operationName = "cancel-request-callback";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (this.$refs.form.validate()) {
          this.disStatus = true;
          this.loading = true;
          let url = requestCallbackSectionApiUrl.closeTicket;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          let body = {
            ticketId,
            comment: this.reason,
            isClosed: "true",
          };
          this.axios.post(this.constants.apiUrl + url, body, config).then(
            (response) => {
              this.loading = false;
              this.dialog = false;
              this.callbackCloseTicket();
            },
            (error) => {
              this.x.error = "Unable to Close Ticket";
            }
          );
        }
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.request-callback-actions {
  padding: 0;
  margin: 0;
}
.ticket {
  margin: 5px 0;
}
.requestCallback {
  margin: auto;
}
</style>
