<template>
  <v-container fluid>
    <v-layout row pt-3>
      <!-- <v-flex xs4>
                <v-menu class="date-picker">
                    <v-text-field
                        :value="endDate"
                        slot="activator"
                        label="End Date"
                        prepend-icon="event"
                    ></v-text-field>
                    <v-date-picker color="#ffc04c" v-model="endDate" @change="searchByDate"></v-date-picker>
                </v-menu>
      </v-flex>-->

      <v-flex xs2 md3>
        <v-select
          v-model="searchTypes"
          :items="searchItems"
          flat
          label="Search Type"
          item-text="status"
          single-line
          class="pt-0"
          item-value="abbr"
          persistent-hint
          @change="searchByType()"
          height="10"
          return-object
          outline
        ></v-select>
      </v-flex>
      <v-flex xs2 md3 pr-3>
        <v-text-field
          height="55px"
          class="gap"
          label="Search here.."
          outline
          :disabled="!searchTypes"
          single-line
          v-model="search"
          @keyup.enter="searchBox"
          @click:append="searchBox"
          append-icon="search"
        ></v-text-field>
      </v-flex>
      <v-flex xs4 pr-3>
        <span class="muted"></span>
        <v-menu
          ref="FilterDateBool"
          lazy
          v-model="FilterDateBool"
          :close-on-content-click="false"
          transition="scale-transition"
          full-width
          :nudge-right="40"
          min-width="290px"
          :return-value.sync="startDate"
        >
          <v-text-field
            slot="activator"
            :label="'Created date'"
            required
            v-model="startDate"
            prepend-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker
            v-model="startDate"
            class="minHeight"
            @change="
              $refs.FilterDateBool.save(startDate);
              searchByDate();
            "
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs1 class="reset">
        <v-btn
          class="reset-btn-color"
          flat
          prepend-icon
          color="orange darken-1"
          @click.native="reset()"
        >
          Reset
          <v-icon color="orange">donut_large</v-icon>
        </v-btn>
      </v-flex>
      <ErrorBox :error="x.error" />
    </v-layout>
  </v-container>
</template>
<script>
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import { EventBus } from "../../event-bus.js";
export default {
  name: "customFilterRequest",
  components: {
    ErrorBox,
  },
  data() {
    return {
      FilterDateBool: false,
      startDate: "",
      endDate: "",
      searchItems: [
        { status: "Customer ID", abbr: "custId" },
        { status: "Company Name/Customer Name", abbr: "companyName" },
        { status: "Customer Email ID", abbr: "email" },
        { status: "Customer Mobile Number", abbr: "mobileNumber" },
        { status: "Request ID", abbr: "requestId" },
      ],
      search: "",
      searchTypes: "",
      x: {
        error: null,
      },
    };
  },
  created() {
    localStorage.removeItem("requestcallbackStartdate");
    localStorage.removeItem("searchRequestCallback");
    localStorage.removeItem("payloadRequest");
    localStorage.removeItem("searchRequestCallbackType");
    EventBus.$on("countryChanged", (val) => {
      if (localStorage.getItem("searchRequestCallback") !== null) {
        this.search = localStorage.searchRequestCallback;

        let searchType = JSON.parse(localStorage.searchRequestCallbackType);
        this.searchTypes = searchType.abbr;
        this.$emit("request-search", this.search);
        this.$emit("request-searchTypes", this.searchTypes);
      }
      if (localStorage.getItem("requestcallbackStartdate") !== null) {
        this.startDate = localStorage.requestcallbackStartdate;
        this.searchByDate();
      }
    });
  },
  methods: {
    reset() {
      localStorage.removeItem("requestcallbackStartdate");
      localStorage.removeItem("searchRequestCallback");
      localStorage.removeItem("payloadRequest");
      localStorage.removeItem("searchRequestCallbackType");
      this.$emit("request-reset");
      this.searchTypes = "";
      this.startDate = null;
      this.endDate = "";
      this.search = "";
    },
    searchByDate() {
      if (this.startDate) {
        //localStorage.setItem("requestcallbackStartdate", this.startDate);
        this.$emit("request-startDateFilter", this.formatDate(this.startDate));
      } else {
        this.x.error = "Both Start Date and End Date is required.";
      }
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    searchBox() {
      // localStorage.setItem("searchRequestCallback", this.search);
      // localStorage.setItem(
      //   "searchRequestCallbackType",
      //   JSON.stringify(this.searchTypes)
      // );

      this.$emit("request-search", this.search);
      this.$emit("request-searchTypes", this.searchTypes.abbr);
    },
    searchByType() {
      this.search = "";
      this.$emit("request-searchTypes", this.searchTypes.abbr);
      this.$emit("request-search", this.search);
      // localStorage.setItem(
      //   "searchRequestCallbackType",
      //   JSON.stringify(this.searchTypes)
      // );
    },
  },
};
</script>
<style scoped>
.date-picker {
  min-width: 90%;
}
.menuable__content__active {
  min-width: 290px !important;
}
.minHeight {
  min-height: 300px;
}
</style>
