<template>
  <v-card class>
    <v-card-title class="bg-clr p-t-0">
      <v-layout justify-space-around wrap>
        <v-avatar tile color="#ffa500" offset-xs1 class="total avatar">
          <span class="white--text status-count breakword">
            Total Requests
            <span class="count">{{ dashboard.totalData }}</span></span
          >
        </v-avatar>

        <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text status-count breakword">
            Open Requests
            <span class="count">{{ dashboard.openRequest }}</span></span
          >
        </v-avatar>

        <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text status-count breakword">
            Closed Requests
            <span class="count">{{ dashboard.closeRequest }}</span></span
          >
        </v-avatar>
      </v-layout>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: ["dashboard"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.dashboard {
  padding: 20px;
  margin: 0 auto;
}
.breakword {
  padding: 10px;
}
</style>
