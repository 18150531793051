<template>
  <div>
    <v-card class="nospace">
      <v-card-text class="nospace">
        <v-data-table
          id="RequestListTable"
          hide-actions
          style="word-break: break-all"
          :headers="headers"
          :items="items"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
        >
          <template slot="items" slot-scope="props">
            <td>
              {{ props.item.customerDetails.customerSerialNumber }}
              <!-- <ActivityLogs
                v-permissions="'view-logs-request-callback'"
                :user="props.item._id"
              /> -->
              <activityLogs
                :permissions="'view-logs-request-callback'"
                :type="'requestTicket'"
                :_id="props.item._id"
                :id="props.item.customerDetails.customerSerialNumber.toString()"
              />
            </td>
            <td>
              <img
                v-if="props.item.workingCountry == 'PAK'"
                src="../../assets/pakflag.png"
                height="20px"
              /><img
                v-if="props.item.workingCountry == 'KSA'"
                src="../../assets/ksaflag.png"
                height="20px"
              /><img
                v-if="props.item.workingCountry == 'UAE'"
                src="../../assets/Uaeflag.png"
                height="20px"
              />
              <img
                v-if="props.item.workingCountry == 'UAE LA'"
                src="../../assets/Uaeflag.png"
                height="20px"
                style="border-radius: 50%"
              />
              <img
                v-if="props.item.workingCountry == 'OMAN'"
                src="../../assets/omanflag.png"
                height="28px"
              />
              <br />{{ props.item.workingCountry || "-" }}
            </td>
            <td
              v-if="
                props.item.customerDetails.name ||
                props.item.customerDetails.email
              "
            >
              <v-icon
                v-if="!props.item.customerDetails.name"
                flat
                prepend-icon
                color="blue"
                @click.native="editCustomer(props.item.customerDetails.userId)"
                class="request-callback-actions"
                title="Edit Customer"
                >edit</v-icon
              >
              <br v-if="!props.item.customerDetails.name" />
              {{ props.item.customerDetails.name }}
              <br v-if="props.item.customerDetails.name" />
              {{ props.item.customerDetails.email }}
              <br v-if="props.item.customerDetails.name" />
              {{ props.item.customerDetails.phoneNo }}
            </td>
            <td v-else>
              <v-icon
                flat
                prepend-icon
                color="blue"
                @click.native="createCustomer()"
                class="request-callback-actions"
                title="Add Customer"
                >add</v-icon
              >
            </td>
            <td>
              {{ getTime(props.item.creationDate) || "N.A" }}
              <br />
              {{ props.item.purpose || "N.A" }}
            </td>
            <td>{{ props.item.bookingId || "N.A" }}</td>
            <td>
              {{ props.item.requestId || "N.A" }}
              <br />
              {{ getStatus(props.item) || "N.A" }}
            </td>
            <td>{{ props.item.Comments || "N.A" }}</td>
            <td v-if="props.item.exceutive.name || props.item.exceutive.email">
              <div>{{ props.item.exceutive.name }}</div>
              <div>{{ props.item.exceutive.email }}</div>
            </td>
            <td v-else>NA</td>
            <td class>
              <button
                v-permissions="'create-booking-request-callback'"
                class="track-ass-btn"
                title="Create Booking"
                @click="checkAdd(props.item)"
                v-if="
                  (props.item.bookingId == '-' ||
                    props.item.bookingId == null) &&
                  props.item.isAcknowedged &&
                  !props.item.isClosed
                "
                :disabled="loading"
              >
                Add
              </button>
              <CloseTicket
                v-permissions="'cancel-request-callback'"
                v-if="!props.item.isClosed && props.item.isAcknowedged"
                :ticketId="props.item._id"
                :callbackCloseTicket="callbackCloseTicket"
              />

              <button
                v-permissions="'acknowledge-request-callback'"
                v-if="!props.item.isAcknowedged"
                @click="checkAck(props.item._id)"
                :disabled="loading"
                class="assign-driver-btn"
                title="Acknowledge Ticket"
              >
                Acknowledge
              </button>
            </td>
          </template>
          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
        <pagination
          :totalPages="totalPages"
          :pages="this.pages"
          @pageNo="page"
          :url="listApiUrl"
          :callback="callback"
          :componentKey="componentKey"
          :action="`request`"
          :payload="payload"
          :type="1"
          v-on:pagination-load="checkLoading"
        />
      </v-card-text>
    </v-card>
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <v-snackbar
      :timeout="6000"
      bottom
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </div>
</template>

<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import { requestcallbackList } from "@/constants/datatable-headers.js";
import { requestCallbackSectionApiUrl } from "@/constants/api-urls.js";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import pagination from "@/components/Pagination/pagination";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import activityLogs from "../Common/activityLogs";
import CloseTicket from "./closeTicket";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import { debuglog } from "util";
import { StorageKeys } from "../../constants/constants";
export default {
  name: "requestList",
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = val;
      this.loading = true;
      this.payload.workingCountry = this.workingCountry;
      this.$eventBus.$emit("on-load");
    });

    if (localStorage.getItem("payloadRequest") !== null) {
      this.payload = JSON.parse(localStorage.payloadRequest);
    } else {
      this.payload = {
        workingCountry: this.workingCountry,
      };
    }
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
  },
  components: {
    pagination,
    activityLogs,
    CloseTicket,
    SuccessDialog,
    ErrorBox,
  },
  props: {
    dashboardData: Function,
    searchText: String,
    startDateFilter: String,
    endDateFilter: String,
    reset: Boolean,
    searchTypes: String,
    updateList: Number,
  },
  watch: {
    pagination(val) {
      if (val["sortBy"] === "customerId") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "custId";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "custId";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "customer-details") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "companyName";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "companyName";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "created-date") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "createDate";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "createDate";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "rid") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "requestId";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "requestId";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "executive") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "executiveName";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "executiveName";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      }

      if (!val["sortBy"]) {
        delete this.payload["sortType"];
        delete this.payload["sortBy"];
        if (val["descending"] == null) {
          this.componentKey = this.componentKey + 1;
        }
        this.$eventBus.$emit("on-load");
      }
      deep: true;
    },

    updateList: function (n) {
      this.componentKey = this.componentKey + 1;
    },
    startDateFilter: function (n, o) {
      if (n != "") {
        this.loading = true;
        this.$set(this.payload, "startDate", n);
      } else {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    endDateFilter: function (n, o) {
      if (n != "") {
        this.loading = true;
        this.$set(this.payload, "endDate", n);
      } else {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    reset: function (n, o) {
      if (n == true) {
        this.loading = true;
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    searchText: function (n, o) {
      this.loading = true;
      if (n != "") {
        this.payload.searchType = this.searchTypes;
        this.$set(this.payload, "searchText", n);
      } else {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },

    deep: true,
    immediate: true,
  },
  data() {
    return {
      workingCountry: null,
      listApiUrl: requestCallbackSectionApiUrl.callbackList,
      operationName: "",
      componentKey: 0,
      search: "",
      pagination: {},

      isNoSearchResult: false,
      loading: false,
      payload: {},
      pages: 1,
      totalPages: 0,
      x: {
        message: "",
        loading: false,
        success: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },
      headers: requestcallbackList,
      items: [],
    };
  },
  methods: {
    checkLoading(event) {
      if (event) {
        this.$emit("on-load");
      } else {
        // this.$emit("job-load", false);
        this.$emit("off-load");
      }
    },
    async checkAdd(id) {
      this.operationName = "create-booking-request-callback";
      this.loading = true;
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.createBooking(id);
        this.loading = false;
      } else {
        this.loading = false;
        return;
      }
    },
    async checkAck(id) {
      this.loading = true;
      this.operationName = "acknowledge-request-callback";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        this.acknowledge(id);
      } else {
        this.loading = false;

        return;
      }
    },
    page(event) {
      this.pages = event;
    },
    getTime3(date) {
      return moment.utc(date).format("DD/MM/YYYY");
    },
    callbackCloseTicket() {
      this.componentKey += 1;
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
    },
    paginationPayload(event) {
      this.loading = event;
      this.payload = event;
      localStorage.removeItem("current-customer-page-status");
    },
    editCustomer(id) {
      this.$router.push(`/customer/edit/${id}`);
    },
    createCustomer(id) {
      this.$router.push(`/customer/new`);
    },
    acknowledge(id) {
      let url = requestCallbackSectionApiUrl.acknowledgeCallback;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        ticketId: id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.x.success = true;
          this.x.message = response.data.message;
        },
        (error) => {
          this.x.error = "Unable to Acknowledge Ticket";
        }
      );
    },
    createBooking(item) {
      this.$router.push(`/procurement/new/${item.customerDetails.userId}`);
    },
    getTime(date) {
      return moment(date).format("ll");
    },
    callback(res) {
      localStorage.setItem("payloadRequest", JSON.stringify(this.payload));
      // this.$emit("off-load");
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
      this.pagination.rowsPerPage = 15;
      this.$emit("reset-done");
      this.dashboardData(res);
      window.scrollTo(0, 0);
    },
    getExecutive(exceutive) {
      return createElement("div", `${exceutive.name}`);
    },
    getStatus(data) {
      if (data.isAcknowedged && data.isClosed == false) {
        return "Acknowledged";
      } else if (data.isClosed) {
        return "Closed";
      } else {
        return "Pending";
      }
    },
  },
};
</script>

<style lang="scss">
#RequestListTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#RequestListTable
  .theme--light.v-datatable
  thead
  th.column.sortable.active
  .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}
</style>
<style scoped>
li.btn {
  display: block;
}
ul {
  padding: 0;
}
.request-callback-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.requestCallback {
  margin: auto 0;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
</style>
