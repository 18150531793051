<template>
  <v-container fluid>
    <v-card raised v-if="showScreen">
      <loading :active.sync="loading" />
      <v-flex>
        <Dashboard :dashboard="dashboard" />
      </v-flex>
      <v-flex>
        <CustomFilterRequest
          v-on:request-search="searchText"
          v-on:request-searchTypes="searchTypes"
          v-on:request-startDateFilter="startDateFilter"
          v-on:request-endDateFilter="endDateFilter"
          v-on:request-reset="requestReset"
        />
      </v-flex>
      <v-flex>
        <RequestList
          :searchText="search"
          :searchTypes="filter"
          :TypeFilter="filter"
          :loader="loading"
          :updateList="updateTable"
          :startDateFilter="startDate"
          :endDateFilter="endDate"
          :dashboardData="dashboardData"
          :reset="reset"
          v-on:reset-done="resetDone"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
        />
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import Dashboard from "./dashboard";
import RequestList from "./requestList";
import { EventBus } from "../../event-bus.js";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import CustomFilterRequest from "@/components/Request-Callback/CustomFilter.vue";
import Loading from "vue-loading-overlay";

export default {
  mixins: [checkPermissionsMixin],
  created() {
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });
    this.$eventBus.$on("requestCallback", (value) => {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      if (this.workingCountry.includes(value.workingCountry)) {
        this.updateTable = this.updateTable + 1;
      }
    });

    if (this.$route.query && this.$route.query.workingCountry) {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }

      let key = this.$route.query.workingCountry;
      EventBus.$emit("setCountriesfromUrl", key);
      this.workingCountries.forEach((element, index) => {
        if (element.value === key) {
          element.isSelected = true;
        } else element.isSelected = false;
      });
      localStorage.setItem(
        "workingCountries",
        JSON.stringify(this.workingCountries)
      );
    }

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
  },
  components: {
    Dashboard,
    RequestList,
    CustomFilterRequest,
    Loading,
  },

  data: () => ({
    dashboard: {
      closeRequest: 0,
      openRequest: 0,
      totalData: 0,
    },
    loading: true,
    updateTable: 0,
    showScreen: true,
    filterData: [],
    filtertype: "",
    filter: "",
    search: "",
    startDate: null,
    endDate: "",
    dialog: false,
    reset: false,
  }),
  methods: {
    startLoad() {
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
    },
    requestReset() {
      this.loading = true;
      this.reset = true;
      this.startDate = "";
      this.search = "";
      this.filtertype = "";
    },
    resetDone() {
      this.loading = false;
    },
    dashboardData(event) {
      this.dashboard.closeRequest = event.closeRequest;
      this.dashboard.openRequest = event.openRequest;
      this.dashboard.totalData = event.totalData;
      this.loading = false;
    },
    searchText(event) {
      this.search = event;
      this.reset = false;
    },

    TypeFilter(event) {
      this.search = "";
      this.filtertype = event;
      this.reset = false;
    },
    searchTypes(event) {
      this.filtertype = event;
      this.filter = event;

      this.reset = false;
    },
    startDateFilter(event) {
      this.startDate = event;
      this.reset = false;
    },
    endDateFilter(event) {
      this.endDate = event;
      this.reset = false;
    },
  },
};
</script>

<style></style>
